@import '../../styles/customMediaQueries.css';

.root {
}

.sections {
  background-color: #FCFCFC;
  position: relative;
  margin: 0;
  padding-top: 1px;

  @media (--viewportMLarge) {
    padding-top: 90px;
  }
}

.section {
  background-color: #FCFCFC;

  &:first-child {
    background: none;
  }
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: #FCFCFC;
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.heroContainer {
  background-color: #FCFCFC;
}

.sectionContent {
  margin: 0 auto 120px auto;
  padding: 0 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: 0 auto 185px auto;
    padding: 0 36px;
  }

  @media (--viewportMLarge) {
    max-width: calc(100% - 134px);
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  max-width: 90%;
  margin: -120px auto 55px;
  position: relative;

  @media (--viewportMedium) {
    margin: -220px auto 93px;
  }

  @media (--viewportMLarge) {
    max-width: 1180px;
    margin: 0 auto 93px;
    position: absolute;
    top: -260px;
    left: 0;
    right: 0;
  }

  @media (--viewportLLarge) {
    max-width: 1326px;
    top: -336px;
  }
}
